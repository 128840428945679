/**
 * SINGLE-SPA root config
 * arthur @kalchuka
 * 
 * 
 */
import { registerApplication, start } from "single-spa";
import PubSub from "pubsub-js";
const publish = (event, data) => PubSub.publish(event, data)
const subscribe = (event, callback) => PubSub.subscribe(event, callback)
registerApplication({
  name: "@appmart/auth",
  app: () =>
    System.import(
      "@Appmart/auth"
    ),
  activeWhen: ["#/auth"],
  customProps: {
    publish,
    subscribe,
  }
});
// registerApplication({
//   name: "@appmart/car",
//   app: () =>
//     System.import(
//       "@Appmart/auth"
//     ),
//   activeWhen: ["#/car"],
//   customProps: {
//     publish,
//     subscribe,
//   }
// });
//// Register and load Dashboard parcel if the route is /dahsboard/
registerApplication({
  name: "@appmart/dashboard",
  app: () =>
    System.import(
      "@Appmart/dashboard"
    ),
  activeWhen: ["#/dashboard","#/bursary","#/admissions","#/department","#/settings", "#/gs", "#/academics", "#/students","#/caps"],
  customProps: {
    publish,
    subscribe,
  }
});
//// Register and load Dashboard parcel if the route is /dahsboard/
registerApplication({
  name: "@appmart/bursary",
  app: () =>
    System.import(
      "@Appmart/bursary"
    ),
  activeWhen: ["#/bursary"],
  customProps: {
    publish,
    subscribe,
  }
});

//// Register and load Dashboard parcel if the route is /dahsboard/
registerApplication({
  name: "@appmart/mainadmin",
  app: () =>
    System.import(
      "@Appmart/mainadmin"
    ),
  activeWhen: ["#/admin"],
  customProps: {
    publish,
    subscribe,
  }
});

//// Register and load Dashboard parcel if the route is /dahsboard/
registerApplication({
  name: "@appmart/admissions",
  app: () =>
    System.import(
      "@Appmart/admissions"
    ),
  activeWhen: ["#/admissions","#/biometrics"],
  customProps: {
    publish,
    subscribe,
  }
});

//// Register and load department Application if the route is /department/
registerApplication({
  name: "@appmart/department",
  app: () =>
    System.import(
      "@Appmart/department"
    ),
  activeWhen: ["#/department"],
  customProps: {
    publish,
    subscribe,
  }
});
start({
  urlRerouteOnly: true,
})


//// Register and load settings Application if the route is /settings/
registerApplication({
  name: "@appmart/settings",
  app: () =>
    System.import(
      "@Appmart/settings"
    ),
  activeWhen: ["#/settings"],
  customProps: {
    publish,
    subscribe,
  }
});


//// Register and load settings Application if the route is /gs unit/
registerApplication({
  name: "@appmart/generalStudies",
  app: () =>
    System.import(
      "@Appmart/generalStudies"
    ),
  activeWhen: ["#/gs"],
  customProps: {
    publish,
    subscribe,
  }
});


//// Register and load settings Application if the route is /for Academics Operations/
registerApplication({
  name: "@appmart/academics",
  app: () =>
    System.import(
      "@Appmart/academics"
    ),
  activeWhen: ["#/academics"],
  customProps: {
    publish,
    subscribe,
  }
});

//// Register and load settings Application if the route is /for Students Operations/
registerApplication({
  name: "@appmart/students",
  app: () =>
    System.import(
      "@Appmart/students"
    ),
  activeWhen: ["#/students"],
  customProps: {
    publish,
    subscribe,
  }
});

//// Register and load settings Application if the route is /for Students Operations/
registerApplication({
  name: "@appmart/caps",
  app: () =>
    System.import(
      "@Appmart/caps"
    ),
  activeWhen: ["#/caps"],
  customProps: {
    publish,
    subscribe,
  }
});
start({
  urlRerouteOnly: true,
})